/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";

body {
    background-color: #e9ecef;
}
.dataTableBorder {
  border: 1px solid #d8d8d8;
  border-radius: 3px;
}
.pull-right {
  float: right;
}
.tabCardHead {
  background-color: #607D8B;
  color: #ffffff;
  padding: 3px 10px;
  border-radius: 4px 4px 0 0;
}
.tabCardHead a {
  color: #ffffff !important;
}
.flex-none {
  flex: none !important;
}
.flex-auto {
  flex: auto !important;
}

.ui-widget-overlay{
  z-index: 9999!important;
}

.ui-dialog .ui-dialog-titlebar-icon {
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ui-widget-overlay {
  opacity: 1 !important;
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.ui-dialog-titlebar {
  background-color: #607D8B !important;
}

.ui-dialog .ui-dialog-footer .ui-button {
  background-color: #607D8B !important;
}

.btn-primary {
  background: linear-gradient(45deg, #16416e, #007bff) !important;
  border-color: #007bff !important;
  color: #fff !important;
}
.btn-primary:hover, .btn-primary:active {
  background: linear-gradient(45deg, #007bff, #16416e) !important;
  color: #fff !important;
  border-color: #007bff !important;
}

.ui-toast-message-error {
  background-color: #ffe4e4;
  div {
    span.ui-toast-icon {
      color: red !important;
    }
    div {
      div.ui-toast-detail {
        color: red !important;
      }
    }
  }
}

.ui-toast-message-success {
  background-color: #dcfbe8;
  div {
    span.ui-toast-icon {
      color: green !important;
    }
    div {
      div.ui-toast-detail {
        color: green !important;
      }
    }
  }
}

.ui-toast-message-warn {
  background-color: #fff4e0;
  div {
    span.ui-toast-icon {
      color: orange !important;
    }
    div {
      div.ui-toast-detail {
        color: orange !important;
      }
    }
  }
}

.ui-toast-message-info {
  background-color: #dadaff;
  div {
    span.ui-toast-icon {
      color: blue !important;
    }
    div {
      div.ui-toast-detail {
        color: blue !important;
      }
    }
  }
}

.one-liner {
  overflow: hidden;
  white-space: break-spaces;
  text-overflow: ellipsis;
  max-height: 1.5rem;
  word-break: break-all;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  width: -moz-fit-content !important;
  width: fit-content !important;
}
